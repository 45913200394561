import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Button } from '@popmenu/admin-ui';
import { Icon, Typography } from '@popmenu/common-ui';
import { compose } from '@shakacode/recompose';
import { FormattedMessage } from 'react-intl';
import { Heart, Message } from '@popmenu/web-icons';
import { useHistory } from 'react-router-dom';
import { formatCurrency, nl2br } from '../../../utils/utils';
import { withTheme } from '../../../utils/withTheme';
import { classNames } from '../../../utils/withStyles';
import { openEditMenuItemCartModalFromCustomPage } from '../../../shared/DishActions';
import { setSelectedMenuItem, setMenuItemCartLocationId } from '../../../shared/MenuItemCartActions';
import { AH } from '../../shared/AccessibleHeading';

const FeaturedItemsCardContent = (
  { classButton,
    classButtonContainer,
    classContainer,
    classEngagementIcons,
    classItemDescription,
    classItemDescriptionContainer,
    classLikeIcon,
    classLikeIconContainer,
    classLikeIconText,
    classPrice,
    classPriceContainer,
    classReview,
    classReviewContainer,
    classReviewIcon,
    classReviewIconContainer,
    classReviewIconText,
    classTitle,
    featuredItem,
    isOrderingEnabled,
    isPreview,
    priceWithDescription,
    priceWithReview,
    theme,
    sizeLikeIcon,
    sizeReviewIcon,
    showItemDescription,
    showReview,
    showReviewFeatured,
    showReviewInDescription,
    variantTitle,
  }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [reviewContent, setReviewContent] = useState(featuredItem?.review?.content);

  const { menuItem } = featuredItem;

  const getTextWidth = (text) => {
    const pmTextDiv = document.createElement('div');
    pmTextDiv.className = 'pmTextDiv';
    pmTextDiv.innerHTML = text;
    pmTextDiv.style.position = 'absolute';
    pmTextDiv.style.whiteSpace = 'nowrap';
    pmTextDiv.style.font = 'inherit';
    document.body.appendChild(pmTextDiv);
    const elPmTextDiv = document.querySelector('.pmTextDiv');
    const textWidth = elPmTextDiv.clientWidth;
    elPmTextDiv.remove();
    return textWidth;
  };

  const truncateReview = () => {
    const el = document.getElementById(`desc-review-${menuItem.id}`);
    const reviewContentLength = featuredItem?.review?.content.length;
    if (el && reviewContentLength) {
      const textWidth = getTextWidth(featuredItem?.review?.content);
      const lines = textWidth / el.offsetWidth;
      if (lines > 2) {
        const maxCharacters = (((reviewContentLength / lines) * 2) - 7);
        setReviewContent(`"${featuredItem?.review?.content.substring(0, maxCharacters)}..."`);
      } else {
        setReviewContent(`"${featuredItem?.review?.content}"`);
      }
    }
  };

  useEffect(() => {
    truncateReview();
  });

  const openModernMenuCartModal = useCallback(() => {
    dispatch(setSelectedMenuItem({
      menuId: menuItem.menu?.id,
      sectionId: menuItem.section?.id,
    }));
    dispatch(setMenuItemCartLocationId(menuItem?.section?.menu?.locationId));
    dispatch(openEditMenuItemCartModalFromCustomPage(menuItem?.id, menuItem?.menu?.id));
  }, [dispatch, menuItem?.id, menuItem.menu?.id, menuItem.section?.id, menuItem?.section?.menu?.locationId]);

  if (!menuItem) {
    return null;
  }

  const totalLoved = menuItem.likedItPopsCount + menuItem.lovedItPopsCount;
  const totalReviewed = menuItem.reviewsCount;
  const menuAvailable = menuItem.isOrderingEnabled && menuItem.isEnabled && menuItem.section.menu.menuOrderingUrl;

  const descriptionPriceDiv = ` - ${formatCurrency(menuItem.price, menuItem.currency, { showSymbol: theme.showCurrencySymbol })}`;
  const descriptionReviewDiv = ` ... ${formatCurrency(menuItem.price, menuItem.currency, { showSymbol: theme.showCurrencySymbol })}`;
  const showPrice = (!featuredItem.review && !menuItem.description);
  const dontShowPriceWithReview = priceWithDescription && menuItem.description;
  const featuredUserReview = `- ${featuredItem?.review?.user?.displayName}`;

  const featuredReviewContent = showReviewFeatured ?
    // eslint-disable-next-line react/jsx-no-literals
    <React.Fragment>&rdquo;{nl2br(featuredItem?.review?.content)}&rdquo;</React.Fragment> :
    <React.Fragment>{nl2br(featuredItem?.review?.content)}{!dontShowPriceWithReview && priceWithReview && descriptionReviewDiv}</React.Fragment>;
  return (
    <section className={classNames(classContainer, 'fi-card-container')}>

      {/* Title Section */}
      <AH typography className={classNames(classTitle, 'fi-card-title')} variant={variantTitle}>
        {menuItem.name}
      </AH>

      {/* Engagement Icons Section */}
      <div className={classNames(classEngagementIcons, 'fi-card-engagement-icons')}>

        {/* Like Icon Section */}
        <div className={classNames(classLikeIconContainer, 'fi-card-like-icon-container')}>
          <Icon
            className={classNames(classLikeIcon, 'fi-card-like-icon')}
            size={sizeLikeIcon}
            icon={Heart}
          />
          <span className={classNames(classLikeIconText, 'fi-card-like-text')}>
            {totalLoved} <FormattedMessage id={`generic.like${totalLoved === 1 ? '' : 's'}`} defaultMessage={totalLoved === 1 ? 'like' : 'likes'} />
          </span>
        </div>

        {/* Review Icon Section */}
        <div className={classNames(classReviewIconContainer, 'fi-card-review-icon-container')}>
          <Icon
            className={classNames(classReviewIcon, 'fi-card-review-icon')}
            size={sizeReviewIcon}
            icon={Message}
          />
          <span className={classNames(classReviewIconText, 'fi-card-review-text')}>
            {totalReviewed} <FormattedMessage id={`generic.review${totalReviewed === 1 ? '' : 's'}`} defaultMessage={totalReviewed === 1 ? 'review' : 'reviews'} />
          </span>
        </div>
      </div>

      {/* Item Description Section */}
      {showItemDescription && (menuItem.description || (showReviewInDescription && featuredItem.review)) && (
        <div className={classNames(classItemDescriptionContainer, 'fi-card-item-description-container')}>
          <Typography id={`desc-review-${menuItem.id}`} className={classNames(classItemDescription, 'fi-card-item-description')}>
            {nl2br(showReviewInDescription && featuredItem.review ? reviewContent : menuItem.description)}{priceWithDescription && descriptionPriceDiv}
          </Typography>
        </div>
      )}

      {/* Review Section */}
      {showReview && featuredItem.review && (
        <div className={classNames(classReviewContainer, 'fi-card-review-container')}>
          <Typography className={classNames(classReview, 'fi-card-review')}>
            {featuredReviewContent}
            {showReviewFeatured && featuredItem?.review?.user?.displayName && (
              <React.Fragment>
                <br />
                {featuredUserReview}
              </React.Fragment>
            )}
          </Typography>
        </div>
      )}

      {/* Price Section */}
      {showPrice && (
        <div className={classNames(classPriceContainer, 'fi-card-price-container')}>
          <Typography className={classNames(classPrice, 'fi-card-price')}>
            {formatCurrency(menuItem.price, menuItem.currency, { showSymbol: theme.showCurrencySymbol })}
          </Typography>
        </div>
      )}

      {/* Button Section */}
      {isOrderingEnabled && (
        <div className={classNames(classButtonContainer, 'fi-card-button-container')}>
          <Button
            className={classNames(classButton, 'fi-card-button')}
            data-cy="add_to_order"
            disabled={!menuAvailable}
            onClick={() => {
              if (!isPreview) {
                openModernMenuCartModal();
                history.push(menuItem.section.menu.menuOrderingUrl);
              }
            }}
          >
            <FormattedMessage id="next_menu_card.order_online" defaultMessage="Order Online" />
          </Button>
        </div>
      )}
    </section>
  );
};

FeaturedItemsCardContent.defaultProps = {
  classButton: null,
  classButtonContainer: null,
  classContainer: null,
  classEngagementIcons: null,
  classItemDescription: null,
  classItemDescriptionContainer: null,
  classLikeIcon: null,
  classLikeIconContainer: null,
  classLikeIconText: null,
  classPrice: null,
  classPriceContainer: null,
  classReview: null,
  classReviewContainer: null,
  classReviewIcon: null,
  classReviewIconContainer: null,
  classReviewIconText: null,
  classTitle: null,
  priceWithDescription: false,
  priceWithReview: false,
  showReviewFeatured: false,
  showReviewInDescription: false,
  sizeLikeIcon: 'large',
  sizeReviewIcon: 'large',
  variantTitle: 'h4',
};

FeaturedItemsCardContent.propTypes = {
  classButton: PropTypes.object,
  classButtonContainer: PropTypes.object,
  classContainer: PropTypes.object,
  classEngagementIcons: PropTypes.object,
  classItemDescription: PropTypes.object,
  classItemDescriptionContainer: PropTypes.object,
  classLikeIcon: PropTypes.object,
  classLikeIconContainer: PropTypes.object,
  classLikeIconText: PropTypes.object,
  classPrice: PropTypes.object,
  classPriceContainer: PropTypes.object,
  classReview: PropTypes.object,
  classReviewContainer: PropTypes.object,
  classReviewIcon: PropTypes.object,
  classReviewIconContainer: PropTypes.object,
  classReviewIconText: PropTypes.object,
  classTitle: PropTypes.object,
  featuredItem: PropTypes.object.isRequired,
  isOrderingEnabled: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool.isRequired,
  priceWithDescription: PropTypes.bool,
  priceWithReview: PropTypes.bool,
  showItemDescription: PropTypes.bool.isRequired,
  showReview: PropTypes.bool.isRequired,
  showReviewFeatured: PropTypes.bool,
  showReviewInDescription: PropTypes.bool,
  sizeLikeIcon: PropTypes.string,
  sizeReviewIcon: PropTypes.string,
  variantTitle: PropTypes.string,
};

export default compose(
  withTheme,
)(FeaturedItemsCardContent);
