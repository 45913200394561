import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Carousel from 'react-material-ui-carousel';
import { Grid } from '@popmenu/common-ui';
import { Button } from '@popmenu/admin-ui';
import { useWindowSizeContext } from '../../../../shared/WindowSizeProvider';
import FeaturedItemsCardContent from '../FeaturedItemsCardContent';
import { openMenuItemModal } from '../../../../shared/DishActions';
import CustomImg from '../../../../shared/CustomImg';
import styles from './styles';

const useStyles = makeStyles(styles);

const CarouselItemCard = ({ classes, featuredItem, inView, isOrderingEnabled, isPreview, openMenuModal }) => {
  if (!inView) return null;

  const { menuItem } = featuredItem;
  const showPhoto = !!(menuItem.showFeaturedPhoto && menuItem.featuredPhoto && menuItem.featuredPhoto.thumbnailUrl);

  const classesContentCard = {
    classButton: classes.buttonStyle,
    classButtonContainer: classes.buttonContainer,
    classEngagementIcons: classes.popsIconsContainer,
    classItemDescription: classes.itemDescription,
    classLikeIcon: classes.popsIconSize,
    classLikeIconContainer: classes.popsIcon,
    classPrice: classes.defaultTextSize,
    classPriceContainer: classes.classPriceContainer,
    classReview: classes.defaultTextSize,
    classReviewIcon: classes.popsIconSize,
    classReviewIconContainer: classes.popsIcon,
    classTitle: classes.dishTitle,
    variantTitle: 'h6',
  };

  const renderLinkOverlay = () => (
    <Button
      data-cy="menu_item_link"
      className={classes.menuLinkOverlay}
      onClick={() => {
        openMenuModal(menuItem.id);
      }}
      variant="contained"
    >
      {' '}
    </Button>
  );
  return (
    <section className={classes.sectionCard}>
      {!isPreview && renderLinkOverlay()}
      {showPhoto && (
        <CustomImg
          className={classes.photoContainer}
          alt={menuItem.name}
          size={'lg'}
          src={menuItem.featuredPhoto.thumbnailUrl}
        />
      )}
      <FeaturedItemsCardContent
        {...classesContentCard}
        featuredItem={featuredItem}
        isOrderingEnabled={isOrderingEnabled}
        isPreview={isPreview}
        priceWithDescription
        showItemDescription
        showReview={false}
        showReviewInDescription
      />
    </section>
  );
};

const FeaturedItemsCarouselLayout = ({ featuredItems, isOrderingEnabled, isPreview }) => {
  const dispatch = useDispatch();

  // The first time the card is loaded, only the first card will be rendered
  const [cardsInView, setCardsInView] = useState([0]);
  const { isMobile } = useWindowSizeContext();
  const classes = useStyles();
  const carouselSettings = {
    animation: 'slide',
    autoPlay: true,
    fullHeightHover: false,
    indicators: false,
    navButtonsAlwaysVisible: true,
    navButtonsProps: {
      style: {
        backgroundColor: 'rgb(0, 0, 0, 0.7)',
        borderRadius: 0,
        color: 'white',
      },
    },
    onChange: (now, previous) => {
      // Need to show current and previous card due to slide animation
      setCardsInView([now, previous]);
    },
    stopAutoPlayOnHover: true,
  };

  const openMenuModal = useCallback((MenuItemId) => {
    dispatch(openMenuItemModal(MenuItemId));
  }, [dispatch]);

  // This reducer group the items to show the quantity desired of slides
  // desktop: 3, mobile: 1
  const groupFeaturedItemsBy = useCallback((number) => {
    let currentIndexGroup = 0;
    const groupSlides = featuredItems.reduce((acc, obj) => {
      if (!acc[currentIndexGroup]) {
        acc[currentIndexGroup] = [];
      }

      if (!acc.groups) {
        acc.groups = [];
      }

      acc[currentIndexGroup].push(obj);
      if (acc[currentIndexGroup].length >= number) {
        acc.groups.push(acc[currentIndexGroup]);
        delete acc[currentIndexGroup];
        currentIndexGroup += 1;
      }

      acc.totalGroups = currentIndexGroup;
      return acc;
    }, {});

    if (groupSlides[groupSlides.totalGroups]) {
      groupSlides.groups.push(groupSlides[groupSlides.totalGroups]);
      delete groupSlides[groupSlides.totalGroups];
    }

    return groupSlides;
  }, [featuredItems]);

  return (
    <div>
      <Carousel {...carouselSettings}>
        {groupFeaturedItemsBy(isMobile ? 1 : 3).groups.map((group, index) => (
          <Grid container inline wrap="nowrap" spacing={4}>
            {group.map(featuredItem => (
              <Grid item xs={12} md={8}>
                <CarouselItemCard
                  classes={classes}
                  featuredItem={featuredItem}
                  inView={cardsInView.includes(index)}
                  isOrderingEnabled={isOrderingEnabled}
                  isPreview={isPreview}
                  openMenuModal={openMenuModal}
                />
              </Grid>
            ))}
          </Grid>
        ))}
      </Carousel>
    </div>
  );
};

FeaturedItemsCarouselLayout.propTypes = {
  featuredItems: PropTypes.array.isRequired,
  isOrderingEnabled: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool.isRequired,
};

export default FeaturedItemsCarouselLayout;
