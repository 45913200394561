import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@popmenu/common-ui';
import { useSelector } from 'react-redux';
import FeaturedItemsSlideLayout from './featured_items/FeaturedItemsSlideLayout';
import FeaturedItemsGridLayout from './featured_items/FeaturedItemsGridLayout';
import FeaturedItemsCarouselLayout from './featured_items/FeaturedItemsCarouselLayout';
import SectionAboutLink from './SectionAboutLink';

const FeaturedItemsSection = (props) => {
  const isPreview = useSelector(state => state.consumer.isPreview);
  const { isOrderingEnabled } = props.restaurant.featureSetting;
  const { featuredItems, featuredItemLayout, showTextBoxLink } = props;
  if (!featuredItems.length) {
    return null;
  }

  let componentLayout;

  switch (featuredItemLayout) {
    case 'grid':
      componentLayout = (
        <FeaturedItemsGridLayout
          featuredItems={featuredItems}
          isPreview={isPreview}
          isOrderingEnabled={isOrderingEnabled}
        />
      );
      break;
    case 'carousel':
      componentLayout = (
        <FeaturedItemsCarouselLayout
          featuredItems={featuredItems}
          isPreview={isPreview}
          isOrderingEnabled={isOrderingEnabled}
        />
      );
      break;
    case 'slide':
      componentLayout = (
        <FeaturedItemsSlideLayout
          featuredItems={featuredItems}
          isPreview={isPreview}
          isOrderingEnabled={isOrderingEnabled}
        />
      );
  }

  return (
    <React.Fragment>
      {componentLayout}
      {
        showTextBoxLink && featuredItemLayout !== 'slide' && (
          <Grid container justifyContent="center" style={{ marginTop: '24px' }}>
            <SectionAboutLink {...props} />
          </Grid>
        )
      }
    </React.Fragment>
  );
};

FeaturedItemsSection.propTypes = {
  featuredItemLayout: PropTypes.string.isRequired,
  featuredItems: PropTypes.array.isRequired,
  showTextBoxLink: PropTypes.bool.isRequired,
};

export default FeaturedItemsSection;
