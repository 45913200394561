export default theme => ({
  buttonContainter: {
    paddingTop: theme.spacing(1),
  },
  buttonStyle: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: 'white !important',
    },
    backgroundColor: 'transparent',
    border: '1px solid',
    borderColor: 'white !important',
    borderRadius: 0,
    color: 'white !important',
    zIndex: 2,
  },
  container: {
    bottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    position: 'absolute',
  },
  descriptionStyle: {
    color: 'white !important',
    fontSize: '14px',
    fontWeight: '400',
  },
  dishTitle: {
    color: 'white !important',
    fontSize: '16px',
    fontWeight: '600',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  menuLinkOverlay: {
    background: 'transparent !important',
    boxShadow: 'none !important',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  popsIcon: {
    color: 'white !important',
    display: 'flex',
    fontSize: '13px',
    gap: '5px',
  },
  popsIconsContainer: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: '500',
    gap: '10px',
    paddingBottom: theme.spacing(1),
  },
  popsIconSize: {
    fontSize: '15px',
  },
  sectionCard: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100%',
    minHeight: '326px',
    position: 'relative',
    width: '100%',
  },
});
