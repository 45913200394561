import { readableFontColor } from '../../../../utils/colors';

export default theme => ({
  buttonContainer: {
    paddingTop: theme.spacing(1),
  },
  buttonStyle: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: readableFontColor(theme.palette.primary.main, theme.palette.text.primary),
    },
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    color: readableFontColor(theme.palette.primary.main, theme.palette.text.primary),
    padding: theme.spacing(1.5),
    zIndex: 2,
  },
  contentContainer: {
    alignSelf: 'center',
    flexBasis: '50%',
    paddingLeft: '50px',
  },
  defaultTextSize: {
    fontSize: '21px',
    fontWeight: '400',
  },
  dishTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
      fontWeight: '600',
    },
    fontSize: '35px',
    fontWeight: '400',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  menuLinkOverlay: {
    background: 'transparent !important',
    boxShadow: 'none !important',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  photo: {
    [theme.breakpoints.down('sm')]: {
      height: '205px',
    },
    height: '486px',
    objectFit: 'cover',
    width: '100%',
  },
  photoContainer: {
    flexBasis: '50%',
    width: '100%',
  },
  popsIcon: {
    display: 'flex',
    fontSize: '16px',
    fontWeight: '400',
    gap: '5px',
  },
  popsIconsContainer: {
    display: 'flex',
    gap: '10px',
    paddingBottom: theme.spacing(1),
  },
  popsIconSize: {
    fontSize: '16px',
  },
  reviewText: {
    backgroundColor: '#F9F4ED',
    fontSize: '21px',
    fontWeight: '400',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  sectionCard: {
    height: '100%',
    minHeight: '486px',
    position: 'relative',
    width: 'calc(100% - 50px)',
  },
  sectionCardInline: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});
