import { readableFontColor } from '../../../../utils/colors';

export default theme => ({
  buttonContainer: {
    paddingTop: theme.spacing(1),
  },
  buttonStyle: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: readableFontColor(theme.palette.primary.main, theme.palette.text.primary),
    },
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    color: readableFontColor(theme.palette.primary.main, theme.palette.text.primary),
    padding: theme.spacing(1.5),
    zIndex: 2,
  },
  classPriceContainer: {
    minHeight: '60px',
  },
  defaultTextSize: {
    fontSize: '14px',
    fontWeight: '400',
  },
  dishTitle: {
    fontSize: '16px',
    fontWeight: '600',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
  },
  itemDescription: {
    fontSize: '14px',
    fontWeight: '400',
    minHeight: '60px',
  },
  menuLinkOverlay: {
    background: 'transparent !important',
    boxShadow: 'none !important',
    height: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 1,
  },
  photoContainer: {
    height: '304px',
    objectFit: 'cover',
    width: '100%',
  },
  popsIcon: {
    display: 'flex',
    fontSize: '13px',
    gap: '5px',
  },
  popsIconsContainer: {
    display: 'flex',
    fontSize: '14px',
    fontWeight: '500',
    gap: '10px',
    paddingBottom: theme.spacing(1),
  },
  popsIconSize: {
    fontSize: '15px',
  },
  sectionCard: {
    height: '100%',
    minHeight: '462px',
    position: 'relative',
    width: '100%',
  },
});
