import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Carousel from 'react-material-ui-carousel';
import { Grid } from '@popmenu/common-ui';
import { Button } from '@popmenu/admin-ui';
import { classNames } from '../../../../utils/withStyles';
import { useWindowSizeContext } from '../../../../shared/WindowSizeProvider';
import FeaturedItemsCardContent from '../FeaturedItemsCardContent';
import { openMenuItemModal } from '../../../../shared/DishActions';
import CustomImg from '../../../../shared/CustomImg';
import styles from './styles';

const useStyles = makeStyles(styles);

const SliderItemCard = ({ classes, isMobile, featuredItem, isOrderingEnabled, isPreview, openMenuModal }) => {
  const { menuItem } = featuredItem;
  const showPhoto = !!(menuItem.showFeaturedPhoto && menuItem.featuredPhoto && menuItem.featuredPhoto.thumbnailUrl);

  const classesContentCard = {
    classButton: classes.buttonStyle,
    classButtonContainer: classes.buttonContainer,
    classContainer: !isMobile && classes.contentContainer,
    classEngagementIcons: classes.popsIconsContainer,
    classItemDescription: classes.defaultTextSize,
    classLikeIcon: classes.popsIconSize,
    classLikeIconContainer: classes.popsIcon,
    classPrice: classes.defaultTextSize,
    classReview: classes.reviewText,
    classReviewIcon: classes.popsIconSize,
    classReviewIconContainer: classes.popsIcon,
    classTitle: classes.dishTitle,
    variantTitle: 'h6',
  };

  const renderLinkOverlay = () => (
    <Button
      data-cy="menu_item_link"
      className={classes.menuLinkOverlay}
      onClick={() => {
        openMenuModal(menuItem.id);
      }}
      variant="contained"
    >
      {' '}
    </Button>
  );
  return (
    <section className={classNames(classes.sectionCard, !isMobile && classes.sectionCardInline)}>
      {!isPreview && renderLinkOverlay()}
      {showPhoto && (
        <div className={!isMobile && classes.photoContainer}>
          <CustomImg
            className={classes.photo}
            alt={menuItem.name}
            size={'lg'}
            src={menuItem.featuredPhoto.thumbnailUrl}
          />
        </div>
      )}
      <FeaturedItemsCardContent
        {...classesContentCard}
        featuredItem={featuredItem}
        isOrderingEnabled={isOrderingEnabled}
        isPreview={isPreview}
        showItemDescription
        showReview
        showReviewFeatured
        priceWithDescription
      />
    </section>
  );
};

const FeaturedItemsSlideLayout = ({ featuredItems, isOrderingEnabled, isPreview }) => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSizeContext();
  const classes = useStyles();
  const slideSettings = {
    activeIndicatorIconButtonProps: {
      style: {
        backgroundColor: 'black',
      },
    },
    animation: 'slide',
    autoPlay: true,
    indicatorIconButtonProps: {
      style: {
        padding: '0',
      },
    },
    indicators: true,
    navButtonsAlwaysVisible: true,
    navButtonsProps: {
      style: {
        backgroundColor: 'rgb(0, 0, 0, 0.7)',
        borderRadius: 0,
        color: 'white',
      },
    },
    stopAutoPlayOnHover: true,
  };

  const openMenuModal = useCallback((MenuItemId) => {
    dispatch(openMenuItemModal(MenuItemId));
  }, [dispatch]);

  return (
    <div>
      <Carousel {...slideSettings}>
        {featuredItems.map(featuredItem => (
          <Grid container>
            <Grid item xs={12} md={12}>
              <SliderItemCard
                classes={classes}
                isMobile={isMobile}
                featuredItem={featuredItem}
                isOrderingEnabled={isOrderingEnabled}
                isPreview={isPreview}
                openMenuModal={openMenuModal}
              />
            </Grid>
          </Grid>
        ))}
      </Carousel>
    </div>
  );
};

FeaturedItemsSlideLayout.propTypes = {
  featuredItems: PropTypes.array.isRequired,
  isOrderingEnabled: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool.isRequired,
};

export default FeaturedItemsSlideLayout;
