import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Grid } from '@popmenu/common-ui';
import { Button } from '@popmenu/admin-ui';
import FeaturedItemsCardContent from '../FeaturedItemsCardContent';
import { openMenuItemModal } from '../../../../shared/DishActions';
import styles from './styles';

const useStyles = makeStyles(styles);

const GridItemCard = ({ classes, featuredItem, isOrderingEnabled, isPreview, openMenuModal }) => {
  const { menuItem } = featuredItem;
  const showPhoto = !!(menuItem.showFeaturedPhoto && menuItem.featuredPhoto && menuItem.featuredPhoto.thumbnailUrl);

  const renderLinkOverlay = () => (
    <Button
      data-cy="menu_item_link"
      className={classes.menuLinkOverlay}
      onClick={() => {
        openMenuModal(menuItem.id);
      }}
      variant="contained"
    >
      {' '}
    </Button>
  );

  const blackLinearGradientCss = 'linear-gradient(to bottom, transparent 0%, black 100%)';

  return (
    <section
      className={classes.sectionCard}
      style={{
        backgroundImage: showPhoto ? `${blackLinearGradientCss}, url("${menuItem.featuredPhoto.thumbnailUrl}")` : blackLinearGradientCss,
      }}
    >
      {!isPreview && renderLinkOverlay()}
      <FeaturedItemsCardContent
        classEngagementIcons={classes.popsIconsContainer}
        classLikeIconContainer={classes.popsIcon}
        classReviewIconContainer={classes.popsIcon}
        classLikeIcon={classes.popsIconSize}
        classReviewIcon={classes.popsIconSize}
        classButtonContainer={classes.buttonContainter}
        classItemDescription={classes.descriptionStyle}
        classPrice={classes.descriptionStyle}
        classButton={classes.buttonStyle}
        classContainer={classes.container}
        featuredItem={featuredItem}
        isOrderingEnabled={isOrderingEnabled}
        isPreview={isPreview}
        showItemDescription
        showReview={false}
        showReviewInDescription
        priceWithDescription
        variantTitle={'h6'}
        classTitle={classes.dishTitle}
      />
    </section>
  );
};

const FeaturedItemsGridLayout = ({ featuredItems, isOrderingEnabled, isPreview }) => {
  const dispatch = useDispatch();
  const openMenuModal = useCallback((MenuItemId) => {
    dispatch(openMenuItemModal(MenuItemId));
  }, [dispatch]);
  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      {featuredItems.map(featuredItem => (
        <Grid item xs={12} md={4}>
          <GridItemCard
            classes={classes}
            featuredItem={featuredItem}
            isOrderingEnabled={isOrderingEnabled}
            isPreview={isPreview}
            openMenuModal={openMenuModal}
          />
        </Grid>
      ))}
    </Grid>
  );
};

FeaturedItemsGridLayout.propTypes = {
  featuredItems: PropTypes.array.isRequired,
  isOrderingEnabled: PropTypes.bool.isRequired,
  isPreview: PropTypes.bool.isRequired,
};

export default FeaturedItemsGridLayout;
